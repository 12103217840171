<template>
  <div class="cadastr-object-panel">
    <div
      class="close-button cadastr_close_button"
      @click="$emit('close-cadastr-object-panel')"
    >
      <div class="el-icon-close"></div>
    </div>
    <el-tabs v-model="activeName">
      <!-- <el-tab-pane v-if="areaVisible" label="Участок" name="area"> -->
        <el-tab-pane label="Участок" name="area">
        <div class="title">Кадастровый номер</div>
        <div class="value">{{ area.cn }}</div>
        <div class="title">Категория</div>
        <div class="value">{{ area.category_type }}</div>
        <div class="title">Адрес</div>
        <div class="value">{{ area.address }}</div>
        <div class="title">Площадь кв.м.</div>
        <div class="value">{{ area.area_value }}</div>
        <div class="title">Кадастровая стоимость в руб.</div>
        <div class="value">{{ area.cad_cost }}</div>
      </el-tab-pane>
      <!-- <el-tab-pane v-if="buildingVisible" label="Строение" name="building"> -->
      <el-tab-pane label="Строение" name="building">
        <div class="title">Кадастровый номер</div>
        <div class="value">{{ building.cn }}</div>
        <div class="title">Наименование</div>
        <div class="value">{{ building.name }}</div>
        <div class="title">Назначение</div>
        <div class="value">{{ building.purpose }}</div>
        <div class="title">Адрес</div>
        <div class="value">{{ building.address }}</div>
        <div class="title">Дата постройки</div>
        <div class="value">{{ building.year_built }}</div>
        <div class="title">Площадь кв.м.</div>
        <div class="value">{{ building.area_value }}</div>
        <div class="title">Кадастровая стоимость в руб.</div>
        <div class="value">{{ building.cad_cost }}</div>
        <div class="title">Этажность</div>
        <div class="value">{{ building.floors }}</div>
      </el-tab-pane>
      <!-- <el-tab-pane v-if="zouitVisible" label="ЗОУИТ" name="zouit"> -->
      <el-tab-pane label="ЗОУИТ" name="zouit">
        <div class="title">Реестровый номер</div>
        <div class="value">{{ zouit.number_zone }}</div>
        <div class="title">Учетный номер</div>
        <div class="value">{{ zouit.acnum }}</div>
        <div class="title">Кадастровый район</div>
        <div class="value">{{ zouit.rayon_cn }}</div>
        <div class="title">Наименование</div>
        <div class="value">{{ zouit.name_zone }}</div>
        <div class="title">Тип</div>
        <div class="value">{{ zouit.zone_kind }}</div>
        <div class="title">Ограничения</div>
        <div class="value">{{ zouit.content_restrictions }}</div>
      </el-tab-pane>
    </el-tabs>

  </div>
</template>

<script>
  export default {
    name: 'CadastrObjectPanel',
    props: {
      building: {
        type: Object,
        default: function () {
          return {}
        }
      },
      area: {
        type: Object,
        default: function () {
          return {}
        }
      },
      zouit: {
        type: Object,
        default: function () {
          return {}
        }
      }
    },
    data () {
      return {
        activeName: 'area'/* ,
        areaVisible: Object.keys(this.area).length > 0,
        buildingVisible: Object.keys(this.building).length > 0,
        zouitVisible: Object.keys(this.zouit).length > 0 */
      }
    },
    mounted() {
      /* this.activeName = this.areaVisible ? 'area' : (this.buildingVisible ? 'building' : (this.zouitVisible ? 'zouit' : null)) */
    } 
  }
</script>

<style scoped>
.cadastr-object-panel {
  width: 325px;
  border-radius: 6px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.16), 0px 2px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 10px;
  top: 68px;
  z-index: 999;
  background-color: white;
  height: 410px;
  overflow-y: overlay;
}
.cadastr-object-panel /deep/ .el-tabs__header {
  background: white
}
.cadastr-object-panel /deep/ .el-tabs__content {
  padding: 16px;
}
.title {
  color: #888;
}
.value {
  margin-bottom: 10px;
}
.cadastr_close_button {
  margin: 1px 0 0 10px;
  position: absolute;
  right: 10px;
  top: 5px;
  cursor: pointer;
  z-index: 10;
}
</style>
